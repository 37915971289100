import axios from "axios";
import { dominio } from "../../config/base";

const url = dominio;

export default {
  async getUserAuth({ commit }) {
    try {
      const result = await axios.get(url + '/user');
      commit("SET_USER", result.data); // Seteamos el user del store userStore;
      commit("setUserGlobal", result.data, {root: true}); // Eviamos para poder actualizar el root  
      return result.data;
    } catch (e) {
      console.log(e);
    }
  },
};
