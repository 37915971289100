<template>
<div>
    <template v-for="item in $store.state.notificaciones">
        <div v-if="item.state == 0 && item.checkin" :key="item.id" class="cursor notfClas d-flex flex-row notifications-hover" @click="$parent.activeModalDatos(item.checkin_id)">
            <div class="icono"><i class="far fa-check-circle"></i></div>
            <div class="contenido d-flex flex-column" style="width: 88%; margin-left: 21px">
                <p class="title">{{ $t('lg.nt.p1') }} {{ item.checkin?.propiedad?.nombre }}</p>
                <div class="body"><strong>{{item.checkin?.nombre}} {{item.checkin?.apellido}}</strong> {{ $t('lg.nt.p2') }}</div>
                <div class="fecha" style="color: #333; text-align: right">
                    <small>{{ moment(item.created_at).fromNow() }}</small>
                </div>
            </div>
        </div>

        <div v-if="item.state == 1 && item.services?.servicio" :key="item.id" class="cursor notfClas d-flex flex-row notifications-hover" @click="$parent.IrPedido(item.checkin_id)">
            <div class="icono"><i class="far fa-check-circle"></i></div>
            <div class="contenido d-flex flex-column" style="width: 88%; margin-left: 21px">
                <p class="title">{{ $t('lg.nt.p3') }}</p>
                <div class="body"><strong>{{item.checkin?.nombre}}</strong> {{ $t('lg.nt.p4') }} {{ item.services?.servicio?.titulo }}</div>
                <div class="fecha" style="color: #333; text-align: right">
                    <small>{{ moment(item.created_at).fromNow() }}</small>
                </div>
            </div>
        </div>
    </template>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.notfClas {

    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 12px;

}
</style>
