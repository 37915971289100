<template>
<div>
    <nav class="navbar navbar-default fixed-top" style="z-index: 999" :style="
        !$store.state.userRoot.cuenta_confirmada ? { top: '28px !important' } : {}
      ">
        <div v-if="!$store.state.userRoot.cuenta_confirmada" v-animate-css="'fadeIn'" class="alertaConfirmacion lg" style="
          background: rgb(25 25 25);
          color: #dedede;
          padding-top: 6px;
          padding-right: 6px;
          padding-bottom: 6px;
          padding-left: 121px !important;
          top: -33px;
          left: 0px;
          position: absolute;
          right: 0px;
        ">
            <div class="confirmaC d-flex flex-row" style="align-items: center; padding-top: 2px">
                <strong> {{ $t("empresa.maqueta.p7") }}</strong>

                <small class="ml-4"> {{ $t("empresa.maqueta.p8") }}</small>

                <small class="ml-3">
                    <a style="color: #fff" v-if="!enviando" href="#" @click="reenviarCorreoElectronico()"><strong>{{ $t("empresa.maqueta.p9") }}</strong>
                    </a>
                    <a v-if="enviando" href="#" style="color: #fff">{{
              $t("empresa.maqueta.p10")
            }}</a>
                </small>
            </div>
        </div>

        <div v-if="!$store.state.userRoot.cuenta_confirmada" v-animate-css="'fadeIn'" class="alertaConfirmacion xs" style="
          background: rgb(25 25 25);
          color: #dedede;
          padding-top: 6px;
          padding-right: 6px;
          padding-bottom: 6px;
          padding-left: 121px !important;
          top: -33px;
          left: 0px;
          position: absolute;
          right: 0px;
        ">
            <div class="confirmaC d-flex flex-row" style="align-items: center; padding-top: 2px">
                <strong> {{ $t("empresa.maqueta.p7") }}</strong>
            </div>
        </div>

        <div class="container" style="padding-right: 7.6%">
            <div class="logo">
                <a href="/business/checkin-management">
                    <img v-animate-css="'zoomIn'" src="/img/1x/logo1.png" alt="" class="lg img-fluid logito" style="" />
                </a>
                <a href="/business/checkin-management">
                    <img v-animate-css="'zoomIn'" src="/img/1x/favicon.jpg" alt="" class="xs img-fluid logito" style="width: 45px; left: 9px" />
                </a>

                <div class="textT lg" v-if="$route.name == 'InvoicesCompra'" style="
              font-weight: 900;
              text-transform: uppercase;
              margin-left: 300px;
            "></div>
            </div>
            <div v-if="
            $store.state.demo &&
            user &&
            user?.servicios &&
            user?.servicios?.state_demo == true &&
            propiedad_select
          " class="demoEti lg" v-animate-css="'slideInDown'" style="
            background: #c12c04;
            padding-inline: 3px;
            padding-block: 3px;
            border-radius: 15px;
            color: #fff;
            font-weight: 500;
            margin-top: -10px;
            margin-left: auto;
            width: 57%;
          ">
                <span style="text-align: left; float: left; margin-left: 21px"><template v-if="
                calcularDiasRestantes(user?.servicios?.created_at) > 0
              ">
                        {{ $t("empresa.home.p1") }} {{ $t("empresa.home.p2") }}
                        <strong>{{
                calcularDiasRestantes(user?.servicios?.created_at)
              }}</strong>
                        {{ $t("empresa.home.p3") }}
                    </template>
                    <template v-else-if="
                calcularDiasRestantes(user?.servicios?.created_at) <= 0
              ">
                        <span style="color: #eee">{{ $t("empresa.home.p4") }}</span>
                    </template></span>
                <i @click.prevent="quitarAlertDemo()" style="
              width: 20px;
              height: 20px;
              background: #fff;
              border-radius: 50px;
              right: 0;
              float: right;
              cursor: pointer;
              color: #333;
            " class="fa-regular fa-xmark ml-3"></i>
            </div>

            <div class="d-flex flex-row" style="align-items: center !important">
                <div class="noV contenedorNotificaciones dropdown d-flex" style="margin-inline: 10px" :class="{ inactive: validateSubMneu() }">
                    <!-- Icono notificacion   -->
                    <i v-if="$store.state.state_notificacion == true" class="fas fa-circle notification-icon" style="
                color: red;
                position: absolute;
                font-size: 8px;
                margin-left: 14px;
              "></i>
                    <button @click="$store.state.state_notificacion = false" type="button" class="dropdown-toggle notification cursor-pointer vs-con-dropdown parent-dropdown" style="" data-toggle="dropdown" aria-expanded="false">
                        <span class="cursor-pointer mt-1 sm:mr-6 feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                            </svg></span>
                    </button>

                    <div class="dropdown-menu notificaciones">
                        <div class="ContextualPopover-arrowContainer" style="position: absolute; right: 69px; top: -8px">
                            <div class="ContextualPopover-arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="9" role="presentation">
                                    <g fill="none" fill-rule="evenodd">
                                        <path fill="#fff" fill-opacity="0.1" d="M1 9.092h19l-6.402-6.74c-1.717-1.806-4.485-1.8-6.196 0L1 9.093zM20.342 8l-6.02-6.336c-2.108-2.22-5.538-2.218-7.645 0L.658 8h19.684z"></path>
                                        <path fill="#fff" d="M7.402 2.353c1.711-1.801 4.48-1.807 6.196 0L20 9.093H1l6.402-6.74z"></path>
                                    </g>
                                </svg>
                            </div>
                        </div>

                        <div class="cabezera-noti">
                            <strong> {{ $t("empresa.maqueta.text6") }} </strong>
                        </div>
                        <template v-if="$store.state.notificaciones.length > 0">
                            <list-notifications />
                        </template>
                        <template v-else>
                            <div style="padding: 28px; margin: auto; text-align: center">
                                <small> {{ $t("empresa.maqueta.text7") }} </small>
                            </div>
                        </template>

                        <router-link class="dropdown-item" :to="{ name: 'notificacionesUser' }">
                            {{ $t("empresa.maqueta.text8") }}
                        </router-link>
                    </div>
                </div>

                <div class="the-navbar__user-meta flex items-center" style="margin-left: 10px">
                    <div class="dropdown" ref="dropdown">
                        <div class="dropdown-toggle parent-dropdown cursor-pointer;" data-toggle="dropdown" aria-expanded="false">
                            <div @click.prevent="activeNav()" class="selectCuenta d-flex" style="align-items: center; cursor: pointer">
                                <div class="favicion" style="margin-right: 12px">
                                    <div v-if="propiedad_select && propiedad_select.logo" class="imageFavicon" style="
                        height: 42px;
                        width: 42px;
                        border-radius: 50px;
                        border: 1px solid #eee;
                        background-repeat: no-repeat;
                      " :style="{
                        'background-image': `url(${
                          $base.server + propiedad_select?.logo
                        })`,
                      }"></div>
                                    <div v-if="propiedad_select && !propiedad_select.logo" class="imageFavicon d-flex" style="
                        height: 42px;
                        width: 42px;
                        border-radius: 50px;
                        border: 1px solid #eee;
                        opacity: 0.7;
                        background-repeat: no-repeat;
                        text-transform: uppercase;
                        justify-content: center;
                        align-items: center;
                        font-weight: bold;
                        font-size: 20px;
                      ">
                                        {{
                        propiedad_select?.nombre
                          ? propiedad_select?.nombre.substring(0, 1)
                          : null
                      }}
                                    </div>
                                </div>
                                <div class="nameCount" style="margin-right: 12px">
                                    {{ truncatedName(propiedad_select?.nombre) }}
                                </div>
                                <div class="iconSelect">
                                    <i class="fa-regular fa-angle-down"></i>
                                </div>
                            </div>

                            <NavMovil class="xs" v-if="navM == true" />
                            <div class="lg dropdown-menu menuPopap user dropdown-menu-right dropdown-menu-arrow">
                                <div class="d-flex flex-column" style="padding: 2px 15px">
                                    <div class="titleProperty">
                                        {{ propiedad_select?.nombre }}
                                    </div>

                                    <div style="text-align: center">
                                        <strong style="font-size: 14px; font-weight: 600">{{
                        $store.state.userRoot.name
                      }}</strong>
                                    </div>
                                </div>

                                <a href="#" class="noV dropdown-item d-flex" style="align-items: center; position: relative" @click.prevent="irRuta('configAccount')">
                                    <i style="
                        position: absolute;
                        top: 12px;
                        color: rgb(124, 124, 124);
                        font-size: 14px;
                        z-index: 2000;
                        right: -35px;
                      " class="fa-duotone fa-circle-question" v-b-tooltip.hover.top :title="$t('lg.nav.p1')"></i>

                                    <i class="fa-regular fa-user-circle mr-2"></i>
                                    {{ $t("lg.nav.p2") }}
                                </a>

                                <a href="#" class="noV dropdown-item d-flex" style="align-items: center; position: relative" @click.prevent="
                      handleOptionClick('data-basic-accommodation')
                    ">
                                    <i style="
                        position: absolute;
                        top: 12px;
                        color: rgb(124, 124, 124);
                        font-size: 14px;
                        z-index: 2000;
                        right: -35px;
                      " class="fa-duotone fa-circle-question" v-b-tooltip.hover.top :title="$t('lg.nav.p3')"></i>

                                    <i class="fa-solid fa-folder-gear mr-2"></i>

                                    {{ $t("lg.nav.p4") }}
                                </a>

                                <a href="#" class="noV dropdown-item d-flex" style="align-items: center; position: relative" @click.prevent="irRuta('Ofertas')">
                                    <i style="
                        position: absolute;
                        top: 12px;
                        color: rgb(124, 124, 124);
                        font-size: 14px;
                        z-index: 2000;
                        right: -35px;
                      " class="fa-duotone fa-circle-question" v-b-tooltip.hover.top :title="$t('lg.nav.p5')"></i>

                                    <i class="fa-regular fa-store mr-2"></i>

                                    {{ $t('lg.nav.p6') }}
                                </a>

                                <a class="dropdown-item d-flex" style="align-items: center" href="#" @click.prevent="logout">
                                    <i class="fa-regular fa-arrow-right-from-bracket mr-2"></i>
                                    {{ $t("empresa.maqueta.text13") }}
                                </a>

                                <hr />
                                <div class="bloquePropie" style="
                      min-height: 118px;
                      overflow: auto;
                      padding-inline: 20px;
                      padding-bottom: 10px;
                      max-height: 377px;
                    ">
                                    <div class="propiedadSelects d-flex flex-row" style="
                        align-items: center;
                        margin-block: 10px;
                        cursor: pointer;
                      " v-for="(item, index) in propiedades" :key="index" @click.prevent="handlePropertySelect(item.id)">
                                        <div class="imagenPropiedad">
                                            <div v-if="item.logo" class="imageFavicon" style="
                            height: 42px;
                            width: 42px;
                            border-radius: 50px;
                            border: 1px solid #eee;
                            background-repeat: no-repeat;
                          " :style="{
                            'background-image': `url(${
                              $base.server + item.logo
                            })`,
                          }"></div>
                                            <div v-if="!item.logo" class="imageFavicon d-flex" style="
                            height: 42px;
                            justify-content: center;
                            align-items: center;
                            width: 42px;
                            border-radius: 50px;
                            border: 1px solid #eee;
                            opacity: 0.7;
                            font-size: 20px;
                            text-transform: uppercase;
                            font-weight: bold;
                          ">
                                                {{ item.nombre.substring(0, 1) }}
                                            </div>
                                        </div>
                                        <div class="nombrePropiedad" style="
                          font-weight: 700;
                          text-transform: uppercase;
                          margin-left: 13px;
                        ">
                                            {{ item.nombre }}
                                        </div>
                                    </div>
                                    <div class="noV propiedadSelects d-flex flex-row" style="align-items: center; cursor: pointer" @click.prevent="irRuta('createPropiedad')">
                                        <div class="imagenPropiedad">
                                            <div class="imageFavicon d-flex" style="
                            justify-content: center;
                            align-items: center;
                            height: 42px;
                            width: 42px;
                            border-radius: 50px;
                            border: 1px solid #eee;
                            background: #eee;
                          ">
                                                <i class="fa-solid fa-plus"></i>
                                            </div>
                                        </div>
                                        <div class="nombrePropiedad" style="font-weight: 700; margin-left: 13px">
                                            {{ $t('lg.nav.p7') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modalDatos />
    </nav>
</div>
</template>

<script>
import ListNotifications from '@/components/Template/listNotifications.vue';
import modalDatos from "./modalDatos.vue";
import NavMovil from "./navMovil.vue";
import { mapState } from 'vuex';

export default {
    components: {
        modalDatos,
        NavMovil,
        ListNotifications,
    },
    mounted() {
        // Listamos las notificaciones
        this.$funtion.Notifications();
        // end list notifications
        $(".dropdown-menu").on("click", function (e) {
            e.stopPropagation();
        });
        this.propiedad = this.$store.state.propiedadActiva;
        this.listPropiedad();
        // this.fetchData();

        this.propiedad_select = this.property;
    },
    data() {
        return {
            enviando: false,
            propiedades: [],
            navM: false,
            propiedad: null,
            mostrar: null,
            propiedad_select: null,
        };
    },

    watch: {
        'property'(val) {
            console.log('Watch property change nav')
            this.propiedad_select = val;
            this.$store.state.propiedadActiva = val.id;
            const storeLocalProperty = localStorage.getItem("propiedadActiva");
            if (!storeLocalProperty) {
                localStorage.setItem("propiedadActiva", val.id);
            }
        }
    },
    computed: {
        ...mapState('property', ['property']),
        ...mapState('user', ['user']),
    },
    methods: {

        quitarAlertDemo() {
            this.$store.state.demo = false;
        },

        calcularDiasRestantes(fecha) {
            const fechaRegistro = this.moment(fecha);
            const ahora = this.moment();
            const diasTranscurridos = ahora.diff(fechaRegistro, "days");
            const diasRestantes = 14 - diasTranscurridos;
            return diasRestantes;
        },

        truncatedName(name) {
            if (!name) return "";
            return name.length > 15 ? name.substring(0, 15) + "..." : name;
        },
        activeNav() {
            console.log("-- activeNav - - ");
            this.navM = true;
        },
        closeDropdown() {
            const dropdownMenu = this.$refs.dropdown.querySelector(".dropdown-menu");
            if (dropdownMenu && dropdownMenu.classList.contains("show")) {
                dropdownMenu.classList.remove("show");
            }
        },

        handleOptionClick(route) {
            this.cambiarRuta(route);
            this.closeDropdown();
        },
        handlePropertySelect(id) {
            this.ChangePropiedad(id);
            this.closeDropdown();
        },

        cambiarRuta(ruta) {
            this.$router.push(
                "/business/properties/edition/" +
                this.$store.state.propiedadActiva +
                "/" +
                ruta
            );
        },
        irRuta(ruta) {
            if (ruta != this.$route.name) {
                this.$router.push({ name: ruta });
            }
            this.closeDropdown();
        },
        irRutaWaba() {
            if (this.user && this.user.waba_default != "EMPRESA") {
                $("#modalWhatsapp").modal("show");
            } else {
                this.$router.push({ name: "wabaIntegration" });
            }
        },
        validateSubMneu() {
            if (this.$route.name == "InvoicesCompra") {
                return true;
            }
        },

        irRutaSub() {
            this.$router.push({ name: "InvoicesCompra" });
        },
        activarModalIdiomas() {
            this.$funtion.modalIdiomas();
        },

        reenviarCorreoElectronico() {
            this.enviando = true;
            let url = this.$base.dominio + "/reenvio/confirmacion";
            const datos = {
                user_id: this.$store.state.userRoot.id,
            };
            this.axios
                .post(url, datos)
                .then((res) => {
                    this.enviando = false;
                    toastr.success(this.$t('lg.nav.p8'));
                })
                .catch((e) => {
                    this.enviando = false;
                });
        },

        activeModalDatos(id) {
            this.$funtion.modalDatosGeneral(id);
        },
        IrPedido(id) {
            localStorage.setItem("service_id", null);
            window.location.href = "/business/service?service_id=" + id;
        },
        ChangePropiedad(propiedad) {
            localStorage.setItem("propiedadActiva", propiedad);
            this.$store.state.propiedadActiva = propiedad;
            if (this.$route.name != "Bookings") {
                this.$router.push({ name: "Bookings" });
            }

            location.reload();
        },

        async fetchData() {
            try {
                const datos = await this.$funtion.propertySelect();
                console.log(" - - 0 0 Funtion fecthData - - 00 - --  ");
                this.propiedad_select = datos;
                this.$store.state.propiedadActiva = datos.id;
                console.log("DATOS - - ", datos);
            } catch (error) {
                console.log(error);
            }
        },

        async getPropiedadActiva() {
            var result = await this.$funtion.propertySelect();
            console.log(result, " RESULT --- -");
        },

        listPropiedad() {
            let url = this.$base.dominio + "/propiedades";
            axios
                .post(url)
                .then((res) => {
                    this.propiedades = res.data;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        logout() {
            this.$store
                .dispatch("destroyToken")
                .then((response) => {
                    window.location.href = "/";
                })
                .catch((error) => {
                    toastr.error(this.$t("empresa.maqueta.text14"));
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 720px) {
    .selectCuenta {
        position: absolute;
        right: -23px;
        top: -27px;
    }

    .menuPopap {
        width: 301px !important;
    }

    .noV {
        display: none !important;
    }

    .bloquePropie {
        min-height: 354px !important;
    }
}

.dropdown-menu .dropdown-item:active {
    color: #fff !important;
}

.dropdown-item {
    color: #525252 !important;
    font-weight: 500 !important;
    margin-block: 0px !important;

    i {
        font-size: 20px;
    }
}

.titleProperty {
    font-weight: 600;
    font-size: 19px;
    color: #3b3b3b;
    text-transform: uppercase;
    text-align: center;
    margin-top: 22px;
}

.menuPopap {
    width: 400px;
    border-radius: 20px !important;
    max-height: 670px !important;
    box-shadow: 0px 0px 27px 3px #0000001c !important;
    margin-top: 10px;
}

.imageFavicon {
    height: 42px;
    width: 42px;
    border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-color: #eee;
    background-position-y: -2px;
}

.nameCount {
    text-transform: uppercase;
    font-weight: 700;
    color: #505050;
}

.contenedorNotificaciones {
    height: 42px;
    width: 42px;
    background: #eee;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
}

.inactive {
    display: none !important;
}

.icon-leng {
    &:hover {
        transition: 0.4s;
        color: rgb(121, 186, 22);
    }
}

.dropdown-item {
    cursor: pointer;
}

@media (min-width: 768px) {
    .logito {
        width: 132px;
        position: absolute;
        top: 13px;
        left: 115px;
    }
}

@media (max-width: 768px) {
    .logito {
        width: 132px;
        position: absolute;
        top: 11px;
    }

    .notification {
        margin-right: 19px !important;
        margin-top: 14px !important;
    }

    .notification-icon {
        color: red;
        position: absolute;
        font-size: 8px;
        margin-left: 14px;
        margin-top: 12px !important;
    }
}

.notifications-hover:hover {
    transition: 0.3s;
    background: #fafafa;
}

.cabezera-noti {
    border-bottom: 1px solid #eee;
    padding: 11px 20px;
    font-size: 18px;
}

.ContextualPopover-arrow {
    z-index: 1;
    width: 21px;
    height: 21px;
    margin: -8px;
}

.dropdown-menu .dropdown-item {
    i {
        width: 25px;
    }
}

.dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 0.5rem 1rem;
    font-weight: bold;
    margin-left: 9px;
    color: #5e72e4;
    width: fit-content;
    margin-left: 9px;
    border-radius: 34px;
    margin-bottom: 12px;

    &:hover {
        transition: 0.2s;
        width: fit-content;
        margin-left: 9px;
        border-radius: 34px;
    }

    &:active {
        text-decoration: none;
        color: #fff;
        background-color: #5e72e4;
        border-radius: 34px;
    }
}

.dropdown-menu.notificaciones {
    min-width: 34rem;
    float: left;
    left: -392px;
    top: 34px;
}

.dropdown-menu.user {
    min-width: 17rem;
}

.dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.navbar {
    // background: white;
    background: #fff;
    height: 78px;
    // border-bottom: 1px solid #eee;
}
</style>
