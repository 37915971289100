export const checkinmanual = {
    text1: "Check-In Manual",
    text2: "Ingresa los datos requeridos para el check-in del cliente.",
    text3: "Datos del alojamiento.",
    text4: "Selecciona el alojamiento *",
    text5: "Datos Personales.",
    text6: "Nombre del cliente *",
    text7: "Apellidos del cliente *",
    text8: "Masculino",
    text9: "Femenino",
    text10: "Prefiero no decirlo",
    text11: "Otro",
    text12: "Seleccione el género *",
    text13: "Fecha de nacimiento *",
    text14: "Número de teléfono *",
    text15: "Información hospedaje",
    text16: "Información de hospedaje obligatoria para poder seguir con el check-in por favor llenar todos los campos.",
    text17: "Desde - Hasta",
    text18: "¿Hasta que fecha se hospedara el cliente?",
    text19: "Correo electrónico del huésped *",
    text20: "Seleccione el tipo de documento.",
    text21: "ID",
    text22: "Pasaporte",
    text23: "Tipo de documento",
    text24: "Numero de documento",
    text25: "Ingresa el número del documento",
    text26: "Expiración del documento",
    text27: "Expiración del documento",
    text28: "Continuar",
    text29: "Check-In Manual",
    text30: "Ingresa los datos requeridos para el check-in del cliente.",
    text31: "Firma del check-in",
    text32: "Firma en el recuadro gris que ves en pantalla",
    text33: "Guardar Check-in",
    text34: "Proceso de Check-in realizado con éxito.",
    text35: "Debe seleccionar un alojamiento para asignar el check-in",
    text36: "El campo nombre es obligatorio",
    text37: "El campo apellido es obligatorio",
    text38: "El campo genero es obligatorio",
    text39: "El campo fecha de nacimiento es obligatorio",
    text40: "El campo Teléfono es obligatorio",
    text41: "El campo fecha de hospedaje es obligatorio",
    text42: "El campo correo electrónico es obligatorio",
    text43: "El campo País es obligatorio",
    text44: "El campo tipo de documento es obligatorio",
    text45: "El campo número de documento es obligatorio",
    text46: "Debe aceptar los términos y condiciones.",
    text47: "Enviando datos de Check-in por favor espere...",
    text48: "Cargando...",
    text49: "completó el check-in con éxito.",
    text50: "Haciendo click aquí podrás ver todos nuestros servicios disponibles en el hotel ",
    text51: "Introducir nombre",
    text52: "Introducir apellido",
    text53: "País",
    text54: "Error",
    text55: "Ingresa el correo electrónico",
    
    // Correcciones 
    p1:`Fecha de entrada`,
    p2:`Fecha de salida`,
    p3:`Fecha de emisión del documento`,
    p4:``,
    p5:``,
    p6:``,
    p7:``,
    p8:``,

};