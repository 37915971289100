export const enviarcheckin = {
    text1: "Send Check-In",
    text2: "Send a link for your customers to check in before arriving at the accommodation.",
    text3: "Select the accommodation *",
    text4: "Enter name",
    text5: "Customer name *",
    text6: "Phone number *",
    text7: "Email",
    text8: "Customer email *",
    text9: "Send",
    text10: "Sending check-in please wait",
    text11: "You must select an accommodation to assign the check-in",
    text12: "Name field is required",
    text13: "Phone field is required",
    text14: "Email is required",
    text15: "Almost done",
    text16: "Hi ",
    text17: "! We are ",
    text18: ". We're sending you this message so you can check in before arriving at our accommodation, thus avoiding possible waits at reception and, above all, the unnecessary consumption of paper. \n\nFor a greener planet!",
    text19: "Hi ",
    text20: "! We are ",
    text21: ". We're sending you this message so you can check in before arriving at our accommodation, thus avoiding possible waits at reception and, above all, the unnecessary consumption of paper. <br> <br> For a greener planet!",
    text22: "Check-in sent successfully",
    text23: "Loading ...",

    // Corrections
    p1: "Send check-in link",
    p2: "Create reservation",
    p3: "Reservation information",
    p4: "Responsible guest name",
    p5: "Check-in date",
    p6: "Check-out date",
    p7: "Number of guests",
    p8: "Select a room",
    p9: "Not specified",
    p10: "Select room number",
    p11: "Online check-in",
    p12: "Reservation price",
    p13: "Create reservation",
    p14:`Now invite your guest to proceed with the check-in.`,
    p15:`Additional charge`,
    p16: "",
    p17: "",
    p18: "",
};
