<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modalLenguaje"
    style="text-align: left"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content" style="padding: 28px">
        <!-- <loaderModal v-if="$store.state.efecto" /> -->

        <div class="modal-header" style="align-items: center">
          <img src="/img/marca/panda9.svg" alt="" style="width: 100px" />
          <h4 style="font-size: 23px; color: #000">{{ $t('empresa.maqueta.p12') }}</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="margin-top: -154px"
          >
            <i class="far fa-times" style="font-size: 20px"></i>
          </button>
        </div>
        <div class="modal-body">
          <strong>{{ $t('empresa.maqueta.p13') }}</strong>
          <div class="row" style="margin-top: 20px">
            <div class="col-12 col-lg-3" style="margin-bottom:15px">
              <div
                class="slectIdioma card"
                :style="
                  $store.state.idioma == 'es'
                    ? { color: '#3F51B5', 'font-weight': 700 }
                    : {}
                "
                @click.prevent="changeLanguage('es')"
                style="padding: 16px; font-weight: 600"
              >
                Español (España)
              </div>
            </div>
            <div class="col-12 col-lg-3" style="margin-bottom:15px">
              <div
                class="slectIdioma card"
                :style="
                  $store.state.idioma == 'en'
                    ? { color: '#3F51B5', 'font-weight': 700 }
                    : {}
                "
                @click.prevent="changeLanguage('en')"
                style="padding: 16px; font-weight: 600"
              >
                English
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loaderModal from "../../../components/Loaders/loaderModal.vue";

export default {
  data() {
    return {
      i18n: null,
    };
  },
  components: {
    loaderModal,
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("appLanguage", lang); // Guarda el idioma seleccionado en localStorage
      this.$store.state.idioma = lang;
      $("#modalLenguaje").modal("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.slectIdioma {
  cursor: pointer;
  &:hover {
    background: #eeeeeec1;
    transition: 0.4s;
  }
}
</style>
