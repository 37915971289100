export const reservas = {
    p1: `Scheduled Check-ins`,
    p2: `List of reservations registered on the platform`,
    p3: `More actions`,
    p4: `Schedule Check-in`,
    p5: `Today`,
    p6: `Reservation code`,
    p7: `Name`,
    p8: `Email`,
    p9: `Check-in date`,
    p10: `Price`,
    p11: `Number of guests`,
    p12: `Status: `,
    p13: `Quantity: `,
    p14: `Status: `,
    p15: `Pending`,
    p16: `Complete`,

    p17: `More actions`,
    p18: `Import reservations`,
    p19: `Download an Excel template, fill in your reservation details, and upload it to create multiple reservations simultaneously.`,

    p20: `Scheduled Check-in`,
    p21: `Detailed information of the scheduled check-in`,
    p22: `Status: `,
    p23: `Paid`,
    p24: `Pending payment`,
    p25: `Change payment status`,
    p26: `Check-in responsible`,
    p27: `Send online check-in`,
    p28: `Sending check-in link ...`,
    p29: `Automatically send online check-in to guests`,
    p30: `One week before check-in`,
    p31: `72 hours before check-in`,
    p32: `48 hours before check-in`,
    p33: `24 hours before check-in`,
    p34: `Save changes`,
    p35: `Check-in date`,
    p36: `Check-out date`,
    p37: `Registered guests`,
    p38: `List of completed check-ins.`,
    p39: `First name`,
    p41: `Last name`,
    p42: `Check-in date`,
    p43: `Email`,
    p44: `Process`,
    p45: `Scanned`,
    p46: `Manual`,
    p47: `Manual operator`,
    p48: `Phone`,
    p49: `No check-ins registered`,

    p50: `Reservation payment status`,
    p51: `Manually change the reservation payment status.`,
    p52: `Select one of the options`,
    p53: `Pending`,
    p54: `Paid`,
    p55: `Cancel`,
    p56: `Change status`,
    p57: `Unspecified`,
    p58: `Complete`,
    p59: `You have not scheduled a check-in yet`,
    p60: `Scheduled check-ins`,
    p61: `Changes saved successfully`,
    p62: `Do you want to send the check-in link?`,
    p63: `Send link`,
    p64: `Cancel`,
    p65: `Check-in link sent successfully`,
    p66: ``,
    p67: ``,
    p68: ``,
}
