var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Nav'),_c('SubNav',{staticClass:"lg"}),_c('TabBottom',{staticClass:"xs"}),(!_vm.$isMobile)?_c('button-soport'):_vm._e(),_c('div',{staticClass:"contenedor-main",style:(_vm.$route.name == 'InvoicesCompra'
          ? {
              background: '#f6f6f6',
              width: '100%',
              'min-height': '100vh',
              padding: '8%',
            }
          : {})},[_c('div',{staticClass:"container-fluid p-0"},[_c('router-view')],1)]),_c('modal-whatsapp'),_c('ModalLenguaje')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }