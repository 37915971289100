export const maqueta = {
    text1: "You have completed",
    text2: "Check-ins",
    text3: "sheets of paper saved 😊",
    text4: "Every 100 Check-ins we plant a tree with",
    text5: "ONE TREE PLANTED",
    text6: "Notifications",
    text7: "You have no notifications",
    text8: "View all",
    text9: "Welcome",
    text10: "Company",
    text11: "All accommodations",
    text12: "Profile",
    text13: "Log out",
    text14: "Sorry, an error occurred while logging out",
    text15: "Your subscription payment was declined",
    text16: "To continue using WACheckin, you need to make your payment",
    text17: "Check-in information.",
    text18: "Check-in data",
    text19: "Request date:",
    text20: " at",
    text21: "Data collected during the guest's check-in",
    text22: "First name",
    text23: "Last name",
    text24: "Phone",
    text25: "Stay date",
    text26: "Document type",
    text27: "Passport",
    text28: "National identity document",
    text29: "Document number",
    text30: "Additional information",
    text31: "Document expiration",
    text32: "Nationality",
    text33: "Date of birth",
    text34: "Gender",
    text35: "Female",
    text36: "Male",
    text37: "Close",
    text38: "Check-Ins",
    text39: "Service requests",
    text40: "Accommodations",
    text41: "Cross-sales",
    text42: "Integrations",
    text43: "Billing",

    // Corrections 
    p1: "Scheduled check-ins",

    // Plan validation corrections
    p2: "Upgrade your plan to perform check-ins",
    p3: "Sorry, you currently have no active subscription or credits for your accommodation.",
    p4: "Upgrade plan",

    p5: "Check-ins are not available at this accommodation.",
    p6: "For more information, you can contact the accommodation owner.",

    p7: "Account not verified",
    p8: "Please check your inbox and confirm your account. Didn't receive the email?",
    p9: "Resend",
    p10: "Sending email, please wait...",
    p11: "My subscription",

    p12: "Language and region",
    p13: "Choose a language and region",
    p14: "Here you can see all the check-ins completed.",
    p15: "Schedule your check-ins in advance and send them to your guests.",
    p16: "Here are displayed additional service requests from your guests, such as rentals, tours, and more.",
    p17: "",
    p18: "",
    p19: "",
    p20: "",
};
