<template>
<div data-test="header" class="n4nc9">
    <div class="AamDj">
        <div style="transform: translateX(228px)" class="iiPdB">
            <button type="button" tabindex="0" class="NAL66 pgfIA">
                <div class="qt27s CFTvZ">
                    <div class="yHzBi">
                        <div></div>
                    </div>
                </div>
            </button>
        </div>
    </div>
    <div class="z315Q">
        <button type="button" tabindex="0" data-test="user-menu-button" class="e7L2R">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#222E3A" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 19.2C9.5 19.2 7.29 17.92 6 15.98C6.03 13.99 10 12.9 12 12.9C13.99 12.9 17.97 13.99 18 15.98C16.71 17.92 14.5 19.2 12 19.2Z"></path>
            </svg>
            <div class="WIDKs d-flex">
                <span class="rRQny">Mi cuenta</span><span class="AldFl">{{ $store.state.userRoot.email }}</span>
            </div>
        </button>
    </div>
</div>
</template>
<script>
  export default {
    name: 'navAdmin'
  }
</script>

<style lang="scss" scoped>
.n4nc9 {
    align-items: center;
    background: #fff;
    border-bottom: 1px solid rgba(34, 46, 58, 0.1);
    box-sizing: border-box;
    display: flex;
    flex: 0 0 82px;
    overflow: hidden;
    z-index: 50;
    position: fixed;
    right: 0 !important;
    left: 228px;
    height: 64px;
}

.n4nc9 .AamDj {
    flex: 0 0 auto;
}

@media only screen and (min-width: 1024px) {
    .iiPdB {
        display: none;
    }
}

.pgfIA {
    transition: all 0.28s ease-out;
}

.NAL66 {
    align-items: center;
    background: 0 0;
    border: none;
    border-radius: 96px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 700;
    height: 48px;
    justify-content: center;
    letter-spacing: 0;
    line-height: 16px;
    outline: none;
    padding: 0;
    text-align: center;
    transition: background 0.2s;
    width: 48px;
    word-break: break-word;
}

.pgfIA .qt27s {
    opacity: 0.5;
    width: 16px;
}

.pgfIA .qt27s.CFTvZ>.yHzBi>div {
    transform: scale(0);
}

.pgfIA .qt27s>.yHzBi:after,
.pgfIA .qt27s>.yHzBi:before,
.pgfIA .qt27s>.yHzBi>div {
    background-color: #222e3a;
    border-radius: 3px;
    content: "";
    display: block;
    height: 2px;
    margin: 3px 0;
    transition: all 0.4s ease-in-out 0.1s;
}

.n4nc9> :not(:first-child) {
    margin-left: 16px;
}

.n4nc9 .z315Q {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    margin: 0 8px;
    overflow: hidden;
}

.e7L2R {
    margin-right: 80px;
    align-items: center;
    background: 0 0;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    flex: 0 1 auto;
    max-height: 48px;
    outline: none;
    overflow: hidden;
    padding: 8px 16px;
    transition: all 0.2s;
}

.e7L2R> :not(:last-child) {
    margin-right: 12px;
}

.e7L2R svg {
    fill: #59626b;
    display: flex;
    flex: 0 0 24px;
}

.e7L2R> :not(:last-child) {
    margin-right: 12px;
}

.WIDKs {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;
    text-align: left;
}

.WIDKs .AldFl,
.WIDKs .rRQny {
    font-weight: 400;
    letter-spacing: 0;
}

.WIDKs .rRQny {
    color: #222e3a;
    font-size: 14px;
    line-height: 16px;
}

.WIDKs> :not(:last-child) {
    margin-bottom: 2px;
}

.WIDKs>* {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.WIDKs {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;
    text-align: left;
}

.WIDKs .AldFl {
    color: #90969c;
    font-size: 13px;
    line-height: 18px;
}

.WIDKs .AldFl,
.WIDKs .rRQny {
    font-weight: 400;
    letter-spacing: 0;
}

.WIDKs>* {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.e7L2R .q9Td_ {
    transform: rotate(0deg);
    transform-origin: 13px center;
    transition: all 0.2s;
}

.e7L2R svg {
    fill: #59626b;
    display: flex;
    flex: 0 0 24px;
}
</style>
