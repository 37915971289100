<template>
<div style="width: auto" class="oybvm bhQ6F _Js_7 _bDHB __4wb">
    <div style="width: 100%" class="cAL7H">
        <div class="UZPY8">
            <div data-test="main-region-side-menu" class="RDlSp">
                <a class="D2fcS NabNO" href="/home/dashboard">
                    <img alt="qr logo" src="/img/1x/logo1.png" style="width: 150px" /></a>

                <div class="_uMjR mt-4">
                    <div class="Kl1W_">
                        <div class="NHzwe">
                            <div class="WTQKT">
                                <div data-test="menu-category-home" class="_FSF0 o5ljf Xokp9" @click.prevent="CambiarRuta('HomeManager')">
                                    <div class="S8QZF">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#222E3A" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.72189 12.8016C1.42604 12.5071 1.42604 12.0281 1.72189 11.7336L11.9999 1.5L22.2781 11.7336C22.574 12.0281 22.574 12.5071 22.2781 12.8016C21.9841 13.0944 21.5088 13.0944 21.2148 12.8016L19.5635 11.1568V19.4924C19.5635 21.1534 18.211 22.5 16.5426 22.5H7.47971C5.81129 22.5 4.45876 21.1534 4.45876 19.4924V11.1347L2.78525 12.8016C2.49123 13.0943 2.01591 13.0943 1.72189 12.8016ZM12 3.62661L18 9.65625V19.4924C18 20.3229 17.3768 20.9962 16.5426 20.9962H14.212V15.0344C14.212 14.2039 13.5358 13.5306 12.7015 13.5306H11.1911C10.3569 13.5306 9.6806 14.2039 9.6806 15.0344V20.9962H7.47971C6.6455 20.9962 6.00001 20.3229 6.00001 19.4924V9.62601L12 3.62661Z"></path>
                                        </svg>
                                    </div>
                                    <h3 class="lWrX3">Home</h3>
                                </div>
                            </div>

                            <div class="WTQKT">
                                <a href="/alert" data-test="menu-category-alert" class="_FSF0" :class="{ active: $route.name == 'advisorsPage' }" @click.prevent="CambiarRuta('advisorsPage')">
                                    <div class="S8QZF">
                                        <i class="fa-regular fa-users" style="
                          font-size: 17px;
                          margin-left: -4px;
                          color: #222e3a;
                        "></i>
                                    </div>
                                    <h3 class="lWrX3">Asesores</h3>
                                </a>
                            </div>

                            <div class="WTQKT">
                                <div :class="{ active: $route.name == 'UsuariosManager' }" data-test="menu-category-vpn" class="_FSF0" @click.prevent="CambiarRuta('UsuariosManager')">
                                    <div class="S8QZF">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#222E3A" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4a4 4 0 100 8 4 4 0 000-8zm2.1 4a2.1 2.1 0 10-4.2 0 2.1 2.1 0 004.2 0zm4 9c0-.6-3.1-2.1-6.1-2.1-3 0-6.1 1.5-6.1 2.1v1.1h12.2V17zM4 18v-1c0-2.7 5.3-4 8-4s8 1.3 8 4v1a2 2 0 01-2 2H6a2 2 0 01-2-2z"></path>
                                        </svg>
                                    </div>
                                    <h3 class="lWrX3">Usuarios</h3>
                                </div>
                            </div>

                            <div class="WTQKT">
                                <a href="#" data-test="menu-category-incogni" class="_FSF0 jt1M_">
                                    <div class="S8QZF">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#222E3A" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 6H12.6546C11.9205 6.3795 11.2649 6.88977 10.7188 7.5H7.5C5.01472 7.5 3 9.51472 3 12C3 14.4853 5.01472 16.5 7.5 16.5H10.7188C11.2649 17.1102 11.9205 17.6205 12.6546 18H7.5C4.18629 18 1.5 15.3137 1.5 12C1.5 8.68629 4.18629 6 7.5 6ZM22.5 12C22.5 15.3137 19.8137 18 16.5 18C13.1863 18 10.5 15.3137 10.5 12C10.5 8.68629 13.1863 6 16.5 6L16.5202 6.00003L16.5404 6C19.8318 6 22.5 8.65214 22.5 11.9237L22.4999 11.9652L22.5 12Z"></path>
                                        </svg>
                                    </div>
                                    <h3 class="lWrX3" @click.prevent="logout()">
                                        Cerrar sesión
                                    </h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: "sidebarAdmin",
    components: {
    },
    methods: {
       
        CambiarRuta(ruta) {
            this.$router.push({ name: ruta });
        },
        logout() {
            this.$store
                .dispatch("destroyToken")
                .then((response) => {
                    console.log(response);
                    window.location.href = "/";
                })
                .catch((error) => {
                    console.log(error);
                    toastr.error(this.$t("empresa.maqueta.text14"));
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.lWrX3 {
    margin-bottom: 0 !important;
}

._FSF0 {
    cursor: pointer !important;
}

._FSF0.active {
    h3 {
        color: #11b14d !important;
    }

    svg {
        fill: #11b14d !important;
    }

    .far,
    .fas,
    .fal {
        fill: #11b14d !important;
        color: #11b14d !important;
    }
}

._FSF0:hover {
    transition: 0.4s;

    h3 {
        color: #11b14d !important;
        cursor: pointer;
        transform: 0.4s;
    }

    svg {
        transform: 0.4s;
        fill: #11b14d !important;
    }

    .far,
    .fas,
    .fal {
        transform: 0.4s;
        fill: #11b14d !important;
        color: #11b14d !important;
    }
}

h3 {
    font-weight: 500 !important;
}

.oybvm._bDHB.bhQ6F {
    align-items: flex-end;
    right: auto;
}

.oybvm._bDHB {
    bottom: 0;
    left: 0;
    position: static;
    right: 0;
    top: 0;
    visibility: visible;
}

.__4wb {
    position: static !important;
}

.__4wb {
    //   position: absolute !important;
    //   z-index: 100;
}

.oybvm {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow: hidden !important;
}

.cAL7H {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

.RDlSp,
.UZPY8 {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

.UZPY8 {
    display: flex;
}

.RDlSp,
.UZPY8 {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

.RDlSp {
    background-color: #fff;
    border-right: 1px solid rgba(34, 46, 58, 0.1);
    position: relative;
    width: 228px;
    position: fixed;
    z-index: 1;
}

.D2fcS {
    -webkit-tap-highlight-color: transparent;
    font-weight: 700;
    text-decoration: none;
}

.D2fcS,
.D2fcS:visited {
    color: #178a9e;
}

.RDlSp .NabNO {
    align-items: center;
    display: flex;
    height: 64px;
    padding-left: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.RDlSp ._uMjR {
    box-sizing: border-box;
    height: 100%;
    padding-top: 64px;
    width: 100%;
}

.Kl1W_ {
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    width: 100%;
}

.NHzwe {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
}

.WTQKT:not(:last-child) {
    // border-bottom: 1px solid rgba(34, 46, 58, 0.1);
}

.WTQKT:first-child {
    padding-top: 0;
}

.NHzwe>.WTQKT {
    padding: 12px 0;
}

.WTQKT ._FSF0 {
    align-items: center;
    cursor: default;
    display: flex;
    flex-direction: row;
    padding: 8px 0;
    text-decoration: none;
    transition: all 0.2s;
}

.WTQKT ._FSF0 .S8QZF {
    fill: #222e3a;
    flex-shrink: 0;
    line-height: 0;
    width: 24px;
}

.WTQKT ._FSF0> :not(:last-child) {
    margin-right: 12px;
}

.WTQKT ._FSF0 .S8QZF svg {
    width: 100%;
}
</style>
