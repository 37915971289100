export const reservas = {
    p1:`Check-ins programados`,
    p2:`Listado de las reservas registradas en la plataforma`,
    p3:`Más acciones`,
    p4:`Programar Check-in`,
    p5:`Hoy`,
    p6:`Código de reserva`,
    p7:`Nombre`,
    p8:`Email`,
    p9:`Fecha de ingreso`,
    p10:`Precio`,
    p11:`Número de huéspedes`,
    p12:`Estado: `,
    p13:`Cantidad: `,
    p14:`Estado: `,
    p15:`Pendiente`,
    p16:`Completo`,

    p17:`Más acciones`,
    p18:`Importar reservas`,
    p19:`Descargue una plantilla de Excel, rellene los datos de su reserva y cárguela para crear varias reservas simultáneamente.`,

    p20:`Check-in Prog.`,
    p21:`Información detallada del check-in programado`,
    p22:`Estado: `,
    p23:`Pagada`,
    p24:`Pendiente por pagar`,
    p25:`Cambiar estado del pago`,
    p26:`Responsable del check-in`,
    p27:`Enviar check-in online`,
    p28:`Enviando link del check-in ...`,
    p29:`Enviar check-in online a los huéspedes automáticamente`,
    p30:`Una semana antes del check-in`,
    p31:`72 horas antes del check-in`,
    p32:`48 horas antes del check-in`,
    p33:`24 horas antes del check-in`,
    p34:`Guardar cambios`,
    p35:`fecha de ingreso`,
    p36:`fecha de salida`,
    p37:`Huéspedes registrados`,
    p38:`Listado de check-in realizados.`,
    p39:`Nombre`,
    p41:`Apellido`,
    p42:`Fecha del check-in`,
    p43:`Email`,
    p44:`Proceso`,
    p45:`Escaneado`,
    p46:`Manual`,
    p47:`Manual operador`,
    p48:`Teléfono`,
    p49:`No hay check-in registrados`,

    p50:`Estado del pago de la reserva`,
    p51:`Cambia de forma manual el estado del pago de la reserva.`,
    p52:`Selecciona una de las opciones`,
    p53:`Pendiente`,
    p54:`Pagada`,
    p55:`Cancelar`,
    p56:`Cambiar estado`,
    p57:`Sin especificar`,
    p58:`Completo`,
    p59:`Aun no hay check-ins programados`,
    p60:`Check-in programados`,
    p61:`Los cambios se guardaron con éxito`,
    p62:`¿Quieres enviar el link de check-in?`,
    p63:`Enviar link`,
    p64:`Cancelar`,
    p65:`Link de check-in enviado con éxito`,
    p66:``,
    p67:``,
    p68:``,
}