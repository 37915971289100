<template>
  <div>
    <Nav />

    <div class="contenedor-full">
      <div class="container-fluid p-0">
        <router-view></router-view>
      </div>
    </div>

    <!-- <Footer /> -->
  </div>
</template>
<script>
import Footer from './Footer.vue';
import Nav from "./Nav.vue";
export default {
  components: { Nav, Footer },
  mounted() { },
};
</script>
<style lang="scss">
@media (min-width: 768px) {
  .contenedor-full {
    padding-top: 9% !important;
    width: 83.2%;
    text-align: left;
    margin: auto;
  }
}
</style>
