import { dominio } from "@/config/base";
import axios from "axios";

const actions = {

  async fetchUser({ commit }) {
    let url = dominio + "/user";
    await axios
      .get(url)
      .then((response) => {
        commit("setUserGlobal", response.data);
      })
      .catch((error) => {
        console.log(error);
        console.log(" - -- Error al cargar data user - -- - ");
      });
  },

  retrieveToken(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post(dominio + "/login", {
          email: credentials.email,
          password: credentials.password,
        })
        .then((response) => {
          if (response.data.ok == true) {
            const token = response.data.data.token;
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            localStorage.setItem("access_token", token);
            localStorage.setItem(
              "user",
              JSON.stringify(response.data.data.user)
            );
            localStorage.setItem("type", response.data.data.user.type);
            context.commit("retrieveToken", token);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  destroyToken(context) {
    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        axios
          .post(dominio + "/logout")
          .then((response) => {
            localStorage.removeItem("access_token");            
            localStorage.removeItem("propiedadActiva");
            localStorage.removeItem("type");
            localStorage.removeItem("user");
            context.commit("destroyToken");
            resolve(response);
          })
          .catch((error) => {
            localStorage.removeItem("access_token");            
            localStorage.removeItem("propiedadActiva");
            localStorage.removeItem("type");
            localStorage.removeItem("user");
            context.commit("destroyToken");
            reject(error);
          });
      });
    }
  },
};

export default actions;
