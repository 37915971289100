<template>
<!-- Modal -->
<div class="modal fade" id="modalDatosGeneral" style="text-align: left" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content" style="padding: 28px">
            <loaderModal v-if="$store.state.efecto" />

            <div class="modal-header">
                <h4 style="font-size: 23px; color: #225724">
                    {{ $t("empresa.maqueta.text17") }}
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="functionCerrarModal()">
                    <i class="fa-regular fa-times"></i>
                </button>
            </div>
            <div class="modal-body" v-if="$store.state.datosModal">

                <div class="nombreAlojamiento" style="font-size: 27px; margin-bottom: 20px">
                    {{ $store.state.datosModal?.propiedad?.nombre }}
                </div>

                <strong> {{ $t("empresa.maqueta.text18") }} </strong>
                <div class="fecha">
                    {{ $t("empresa.maqueta.text19") }}
                    {{ moment($store.state.datosModal.created_at).format("DD/MM/YYYY") }}
                    {{ $t("empresa.maqueta.text20") }}
                    {{ moment($store.state.datosModal.created_at).format("hh:mm A") }}
                </div>
                <br />
                <br />
                <template v-if="$store.state.datosModal">
                    <strong> {{ $t("empresa.maqueta.text21") }} </strong>
                    <h2 class="nombre">
                        {{ $store.state.datosModal.nombre }} {{ $store.state.datosModal.apellido }}
                    </h2>

                    <div class="precio d-flex justify-content-between">
                        <div>{{ $t("empresa.maqueta.text22") }}:</div>
                        <div>
                            <strong>{{ $store.state.datosModal.nombre }}</strong>
                        </div>
                    </div>

                    <div class="precio d-flex justify-content-between">
                        <div>{{ $t("empresa.maqueta.text23") }}:</div>
                        <div>
                            <strong>{{ $store.state.datosModal.apellido }}</strong>
                        </div>
                    </div>

                    <div class="precio d-flex justify-content-between">
                        <div>{{ $t("empresa.maqueta.text24") }}:</div>
                        <div>
                            <strong>{{ $store.state.datosModal.cliente.whatsapp }}</strong>
                        </div>
                    </div>
                    <div class="tipo d-flex justify-content-between">
                        <div>{{ $t("empresa.maqueta.text25") }}:</div>
                        <div>
                            {{
                  moment($store.state.datosModal.cliente.fecha_desde_hospedaje).format(
                    "DD/MM/YYYY"
                  )
                }}
                            ->
                            {{
                  moment($store.state.datosModal.cliente.fecha_hospedaje).format(
                    "DD/MM/YYYY"
                  )
                }}
                            <strong>
                                ({{
                    dias(
                      $store.state.datosModal.cliente.fecha_desde_hospedaje,
                    $store.state.datosModal.cliente.fecha_hospedaje
                                    )
                  }}
                                {{ $t("empresa.listbookings.text83") }})
                            </strong>
                        </div>
                    </div>
                    <div class="categoria d-flex justify-content-between">
                        <div>{{ $t("empresa.maqueta.text26") }}:</div>
                        <div>
                            <template v-if="$store.state.datosModal.type_documento == 'P'">
                                {{ $t("empresa.maqueta.text27") }}
                            </template>
                            <template v-else> {{ $t("empresa.maqueta.text28") }} </template>
                        </div>
                    </div>
                    <div class="proveedor d-flex justify-content-between" v-if="$store.state.datosModal">
                        <div>{{ $t("empresa.maqueta.text29") }}:</div>
                        <div>
                            <strong>{{ $store.state.datosModal.nu_documento }}</strong>
                        </div>
                    </div>
                    <!-- <div class="proveedor d-flex justify-content-between" v-else>
                  <div>Proveedor:</div>
                  <div>Internal Service</div>
                </div> -->
                </template>

                <hr />
                <br />

                <strong> {{ $t("empresa.maqueta.text30") }} </strong>

                <br v-if="$store.state.datosModal.cliente && $store.state.datosModal.cliente.emision" />
                <div class="font-data d-flex justify-content-between" v-if="$store.state.datosModal.cliente && $store.state.datosModal.cliente.emision">
                    <div class="font-clave">{{ $t("app.t.text91") }}:</div>
                    <div>
                        <strong>{{ moment($store.state.datosModal.cliente.emision).format('DD/MM/YYYY') }}</strong>
                    </div>
                </div>

                <div class="telefono d-flex justify-content-between">
                    <div>{{ $t("empresa.maqueta.text31") }}:</div>
                    <div>
                        <strong>{{ moment($store.state.datosModal.cliente.date_of_expiry).format('DD/MM/YYYY') }}</strong>
                    </div>
                </div>

                <div class="correo d-flex justify-content-between">
                    <div>{{ $t("empresa.maqueta.text32") }}:</div>
                    <div>{{ $store.state.datosModal.cliente.pais_code }}</div>
                </div>
                <div class="tipo_documento d-flex justify-content-between">
                    <div>{{ $t("empresa.maqueta.text33") }}:</div>
                    <div>
                        {{
                moment($store.state.datosModal.cliente.date_of_birth).format("DD/MM/YYYY")
              }}
                        ({{ edad($store.state.datosModal.cliente.date_of_birth) }}
                        {{ $t("empresa.listbookings.text82") }})
                    </div>
                </div>
                <div class="tipo_documento d-flex justify-content-between">
                    <div>{{ $t("empresa.maqueta.text34") }}:</div>
                    <div>
                        <template v-if="$store.state.datosModal.cliente.sex == 'F'">
                            {{ $t("empresa.maqueta.text35") }}
                        </template>
                        <template v-else-if="($store.state.datosModal.cliente.sex = 'M')">
                            {{ $t("empresa.maqueta.text36") }}
                        </template>
                        <template v-else> {{ $store.state.datosModal.cliente.sex }} </template>
                    </div>
                </div>

                <table-changes-chekin :checkin="$store.state.datosModal" v-if="$store.state.datosModal.programmed && $store.state.datosModal.programmed.cantidad_payment > 0" />

            </div>
            <div class="modal-footer d-flex justify-content-between" style="    justify-content: flex-end !important;" v-if="$store.state.datosModal">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" style="height: 48px; font-size: 16px; background: #cbcbcb; color: #333">
                    {{ $t("empresa.maqueta.text37") }}
                </button>
                <div></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import loaderModal from "../../../components/Loaders/loaderModal.vue";
import moment from "moment";
import TableChangesChekin from '@/components/Checkins/tableChangesChekin.vue';
export default {
    components: {
        loaderModal,
        TableChangesChekin,
    },
    methods: {
        functionCerrarModal() {
            var _vm = this;
            setTimeout(() => {
                _vm.$store.state.datosModal = null;
            }, 300);
        },
        dias(fecha1, fecha2) {
            var date1 = moment(fecha1);
            var date2 = moment(fecha2);
            var result = date2.diff(date1, "days");
            return result;
        },
        edad(fecha) {
            var nacimiento = moment(fecha);
            var hoy = moment();
            var anios = hoy.diff(nacimiento, "years");
            return anios;
        },
        Cerrar() {
            $("#modalDatos").modal("hide");
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
